.server-feedback {
    gap: 20px;
}

.server-feedback .icon {
  fill: var(--gray);
  grid-column: 1 / 4;
  place-self: center;
  height: 80px;
  width: 80px;
}
