.input-field-container {
    grid-column: 1 / 4;
    display: grid;
    grid-template-rows: 30px 50px;
    align-items: center;
}

.input-field-container .input-field {
    height: 50px;
    border: 1px solid var(--gray);
    border-radius: 5px;
    padding: 10px;
}

.input-field-container .input-field::placeholder {
    font-style: italic;
}