.gui-layout {
  max-width: 500px;
  width: 100vw;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  background-color: var(--gray-light);
}

.gui-layout .gui-child {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.gui-layout :is(.color-pick) {
  grid-template-rows: auto 135px 45px;
  gap: 10px 0;
}

.gui-layout :is(.input-fields) {
  grid-template-rows: auto auto 45px;
  gap: 20px 0;
}

.gui-layout :is(.overview-page) {
  grid-template-rows: auto auto 45px;
  gap: 20px 0;
}

.gui-layout .title {
  grid-row: 1;
  grid-column: 1 / 4;
  font-size: 2em;
  font-weight: bold;
  color: var(--gray);
}

.gui-layout .button {
  grid-row: 3;
}

.gui-layout .button.back {
  grid-column: 1;
}
.gui-layout .button.next {
  grid-column: 3;
}
