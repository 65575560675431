.color-container {
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: repeat(4, min-content);
  grid-template-rows: repeat(auto-fit, min-content);
  gap: 10px 0;
  justify-content: space-between;
  place-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.refill-color .color-container {
  grid-template-columns: repeat(2, min-content);
  justify-content: center;
  gap: 40px;
}

.color-container::-webkit-scrollbar {
  display: none;
}

.color-button {
  border: none;
  position: relative;
  height: 84px;
  width: 84px;
  border-radius: 10px;
  margin: 2px;
}

.color-button::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 4px var(--gray-light) solid;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.color-button[data-current="true"]::before {
  opacity: 1;
}

.color-button::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border: 2px black solid;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.color-button[data-current="true"]::after {
  opacity: 1;
}

@media only screen and (max-width: 400px) {
  .color-container {
    grid-template-columns: repeat(3, min-content);
  }
}
