.overview-page {
  position: relative;
}

.overview-table-container {
  margin: 15px 0;
  grid-column: 1 / 4;
  display: grid;
  grid-template-rows: repeat(5, 40px);
  align-items: center;
}

.overview-page .show-hide-container {
  right: 0px;
  top: 0px;
  position: absolute;
  height: 40px;
  width: 40px;
  display: grid;
  place-items: center;
}

.overview-page .show-hide-container .icon {
  fill: var(--gray);
  stroke: none;
  height: 20px;
  width: 20px;
}

.overview-table-container .config-part {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1;
}

.overview-table-container .config-part.price {
  align-content: flex-end;
  font-size: 14pt;
}

.overview-table-container .config-part:not(:last-child)::after {
  z-index: -1;
  position: absolute;
  content: "";
  inset: 0;
  border-bottom: 1px solid var(--gray);
}

.overview-table-container .config-part .config-title {
  align-self: center;
}

.overview-table-container .config-part .config-value {
  align-self: center;
  justify-self: end;
}

.overview-table-container .config-part:not(.price) .config-value {
  font-style: italic;
}

.overview-table-container .config-part.price .config-value {
  font-weight: bold;
}
