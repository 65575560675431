.button {
  border: none;
  text-align: center;
  height: 45px;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  
}

.button.next {
    color: white;
  background-color: var(--blue);
}

.button.back {
    color: var(--blue);
    border: 1px solid var(--blue);
}
